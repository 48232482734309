export default {
    type: 'Table',
    filter_keys: [ // Optional
        ['<filter1-key', '<filter1-label']
    ],
    table_settings: { // Overrides formatting settings returned in response
        n: { // n = an integer specifying the index of column settings should be applied to (column after country is 0,1,2)
            decimal: 2, // Rounding applied to column values
            append_value: '%', // Append chars to all column values
            icon: 'circle', // Apply icon to table cell - since cannot do comparison logic, will typically be used to hide icon with `icon: null`
            icon_value: '1',
            icon_label: 'Goal comparison',
        }
    }
}
